import React, { useEffect, useState } from "react"
import "./UserPanel.sass"
import imageLogin from "../../static/login-image.png"
import imageLogo from "../../static/logo-adigital-white.png"

const UserPanel = () => {
  let [user, setUser] = useState(null)

  const logout = () => {
    localStorage.removeItem("ssoToken")
    window.location.replace(window.location.protocol + "//" + window.location.host + "/login2")
  }

  useEffect(async () => {
    const ssoToken = localStorage.getItem('ssoToken')
    if (!ssoToken) {
      window.location.replace(window.location.protocol + "//" + window.location.host + "/login2")
    }

    if (!user) setUser(JSON.parse(Buffer.from(ssoToken.split(".")[1], "base64").toString("utf8")))
  })

  return (
    <div id="userPanel">
      <div className="logo">
        <img src={imageLogo} />
      </div>
      <div className="userPanel-content-container">
        <div className="form-login">
          <div className="userPanel-content">
            <h2>¡Hola, {user ? user.profile.name : ''}!</h2>
            <p>
              Estás en el portal de acceso único de Adigital, que te permite acceder a todos los recursos que Adigital pone a tu disposición
              únicamente con tu dirección de correo electrónico de empresa.
            </p>

            <p>
              Ahora mismo, has iniciado sesión como:
              <ul>
                <li>Nombre: {user ? user.profile.name : ''} {user ? user.profile.surname : ''}</li>
                <li>Empresa: {user ? user.profile.companyName : ''}</li>
                <li>Cargo: {user ? user.profile.position : ''}</li>
              </ul>
              <p className="smaller-paragraph">
                Si alguno de estos datos es incorrecto, contacta con nosotros en <a href="mailto:info@adigital.org">info@adigital.org</a>.
              </p>
            </p>
            <p className="even-smaller-paragraph">
              ¿No sabes a dónde ir ahora? Empieza por <a href="https://www.adigital.org">www.adigital.org</a>.
            </p>
            <div className="foot">
              <p>
                ¿No eres {user ? user.profile.name : ''} {user ? user.profile.surname : ''} o quieres cerrar
                sesión? <button className="button-link" onClick={logout}>Pulsa aquí</button>.
              </p>
              <p>
                Si necesitas ayuda, puedes contactar con nosotros en:{" "}
                <a href="mailto:info@adigital.org">info@adigital.org</a>.
              </p>
            </div>
          </div>
        </div>
        <div className="login-image">
          <img src={imageLogin} />
        </div>
      </div>
    </div>
  )

}

export default UserPanel