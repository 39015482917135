import React, { useEffect, useState } from "react"
import "./Register.sass"
import { useLocation } from "react-router-dom"
import { authenticatedRegistration, validateRegistrationToken } from "../../hooks/api2"
import imageLogin from "../../static/login-image.png"
import imageLogo from "../../static/logo-adigital-white.png"

const Register = () => {
  const useQuery = () => {
    const { search } = useLocation()
    return React.useMemo(() => new URLSearchParams(search), [search])
  }

  let query = useQuery()
  let [parsedToken, setParsedToken] = useState(null)
  let [registerDone, setRegisterDone] = useState(false)
  let [sendingRequest, setSendingRequest] = useState(false)
  let [registerFail, setRegisterFail] = useState(false)
  let [registrationLinkError, setRegistrationLinkError] = useState(false)

  let [formName, setFormName] = useState("")
  let [formSurname, setFormSurname] = useState("")
  let [formPosition, setFormPosition] = useState("")
  let [formAccountIndex, setFormAccountIndex] = useState("-1")

  // SSO Token validation
  useEffect(async () => {
    if (!parsedToken) {
      const registrationToken = query.get("registrationToken")

      if (registrationToken) {
        const req = await validateRegistrationToken({ registrationToken })

        if (req.ok) {
          const token = JSON.parse(Buffer.from(registrationToken.split(".")[1], "base64").toString("utf8"))
          if (token && token.sub && token.accountsData) setParsedToken(token)
          else setRegistrationLinkError(true)
        } else {
          if (req.status === 403) setRegistrationLinkError(true)
          else setRegisterFail(true)
        }
      } else setRegistrationLinkError(true)
    }
  })

  const registerFormSubmit = async (e) => {
    setSendingRequest(true)
    e.preventDefault()

    const res = await authenticatedRegistration({
      registrationToken: query.get("registrationToken"),
      contactName: formName,
      contactSurname: formSurname,
      contactPosition: formPosition,
      contactAccountIndex: formAccountIndex
    })

    if (res.status === 200) {
      const resBody = await res.json()

      const redirect = query.get('redirect')
      const domain = query.get('domain')

      const redirectLink = new URL(`${window.location.protocol}//${window.location.host}/login2`)
      redirectLink.searchParams.append('ssoToken', resBody.token)
      if (redirect) redirectLink.searchParams.append('redirect', redirect)
      if (domain) redirectLink.searchParams.append('domain', domain)

      window.location.replace(redirectLink)
    } else {
      setRegisterFail(true)
    }
  }

  const registerFailPage = () => {
    return (
      <div className="register-content">
        <div className="form-register">
          <div className="form-content">
            <h2>¡Ups!</h2>
            <p>
              Se ha producido un error mientras procesábamos tu registro. Por favor, inténtalo de nuevo dentro de 5 minutos {" "}
              <a href="" onClick={() => window.location.reload()}>
                pulsando aquí
              </a>.
            </p>
            <p>
              Si el error persiste, puedes contactar con nosotros en:{" "}
              <a href="mailto: info@adigital.org">info@adigital.org</a>.
            </p>
          </div>
        </div>
        <div className="login-image">
          <img src={imageLogin} />
        </div>
      </div>
    )
  }

  const registerFormPage = () => {
    const email = parsedToken ? parsedToken.sub : "Obteniendo datos..."
    const accountsData = parsedToken ? parsedToken.accountsData : null

    return (
      <div className="register-content">
        <div className="form-register">
          <form className="form" onSubmit={registerFormSubmit}>
            <div className="form-content">
              <p>Introduce tus datos para registrarte en Adigital</p>
              <input
                value={formName}
                onChange={(e) => setFormName(e.target.value)}
                placeholder="Nombre"
                disabled={sendingRequest}
                type="text"
              />
              <input
                value={formSurname}
                onChange={(e) => setFormSurname(e.target.value)}
                placeholder="Apellidos"
                disabled={sendingRequest}
                type="text"
              />
              <input
                value={formPosition}
                onChange={(e) => setFormPosition(e.target.value)}
                placeholder="Cargo"
                disabled={sendingRequest}
                type="text"
              />
              <input value={email} readOnly={true} disabled={true} type="text" />
              <select
                value={formAccountIndex}
                disabled={!accountsData || sendingRequest}
                onChange={(e) => setFormAccountIndex(e.target.value)}
              >
                <option value={-1}>{accountsData ? `Selecciona tu empresa` : 'Obteniendo datos...'}</option>
                {accountsData &&
                  accountsData.map((account, i) => (
                    <option key={i} value={i}>
                      {`${account.name} (${account.corporate_name})`}
                    </option>
                  ))
                }
              </select>
              <button
                disabled={
                  formAccountIndex === "-1" ||
                  formPosition === "" ||
                  formName === "" ||
                  formSurname === "" ||
                  sendingRequest === true
                }
                className={
                  formAccountIndex === "-1" ||
                    formPosition === "" ||
                    formName === "" ||
                    formSurname === "" ||
                    sendingRequest === true
                    ? "empty-button"
                    : "ready-button"
                }
                type="submit"
              >
                {sendingRequest ? "Inscribiendo..." : "Inscribirse"}
              </button>
            </div>
          </form>
        </div>
        <div className="login-image">
          <img src={imageLogin} />
        </div>
      </div>
    )
  }

  const registerDonePage = () => {
    return (
      <div className="register-content">
        <div className="form-register">
          <div className="form-content">
            <h2>¡Listo!</h2>
            <p>Acabamos de enviar a tu correo electrónico el enlace de acceso.</p>

            <p className="smaller-paragraph">
              Si transcurridos unos minutos no lo recibes, {" "}
              <a href="" onClick={() => window.location.reload()}>
                haz clic aquí para volver a intentarlo
              </a>{" "}
              o contacta con nosotros en <a href="mailto: info@adigital.org">info@adigital.org</a>.
            </p>
          </div>
        </div>
        <div className="login-image">
          <img src={imageLogin} />
        </div>
      </div>
    )
  }

  const registrationLinkErrorPage = () => {
    return (
      <div className="register-content">
        <div className="form-register">
          <div className="form-content">
            <h2>¡Ups!</h2>
            <p>
              Parece que el enlace de registro no es válido o ha caducado.
            </p>
            <p>
              Si necesitas ayuda, puedes contactar con nosotros en:{" "}
              <a href="mailto: info@adigital.org">info@adigital.org</a>.
            </p>
          </div>
        </div>
        <div className="login-image">
          <img src={imageLogin} />
        </div>
      </div>
    )
  }

  const selectContent = () => {
    if (registerFail) return registerFailPage()
    else if (registerDone) return registerDonePage()
    else if (registrationLinkError) return registrationLinkErrorPage()
    else return registerFormPage()
  }

  return (
    <div id="register">
      <div className="logo">
        <img src={imageLogo} />
      </div>

      {selectContent()}

    </div>
  )

}

export default Register