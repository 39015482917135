import React from "react"
import { Switch, Route } from "react-router-dom"
import Home from "./Home"
import { ModalWrapper, Modal } from "@gluedigital/modal"
import WaitingRoom from "./WaitingRoom"
import Scanner from "./Scanner"
import TicketingInvite from "./TicketingInvite/TicketingInvite"
import Login from "./Login/Login"
import { Helmet } from "react-helmet-async"
import Logout from "./Logout/Logout"
import Error from "./Error/Error"
import EventManualInvite from "./EventManualInvite/EventManualInvite"
import Login2 from "./Login2/Login2"
import Register from "./Register/Register"
import UserPanel from "./UserPanel/UserPanel"
import EventLobby from "./EventLobby/EventLobby"

const App = () => (
  <>
    <ModalWrapper>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      </Helmet>

      <Switch>
        <Route path="/" exact>
          <Home />
        </Route>
        <Route path="/login" exact>
          <Login />
        </Route>
        <Route path="/login2" exact>
          <Login2 />
        </Route>
        <Route path="/register" exact>
          <Register />
        </Route>
        <Route path="/logout" exact>
          <Logout />
        </Route>
        <Route path="/userPanel" exact>
          <UserPanel />
        </Route>
        <Route path="/error" exact>
          <Error />
        </Route>
        <Route path="/waiting" exact>
          <WaitingRoom />
        </Route>
        <Route path="/event/:id/:mode" exact>
          <EventLobby />
        </Route>
        <Route path="/scanner" exact>
          <Scanner />
        </Route>
        <Route path="/ticketing-invite/:id" exact>
          <TicketingInvite />
        </Route>
        <Route path="/event/:id/invite" exact>
          <EventManualInvite />
        </Route>
      </Switch>
      <Modal />
    </ModalWrapper>
  </>
)

export default App
